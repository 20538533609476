import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { theme } from 'app/theme/index'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  id?: string
  title: string
  languageCode?: string
  languagePrefix?: string | null
  URL: string
}

export const MenuItem = memo(function MenuItem({ title, URL }: Props) {
  if (!title) {
    return null
  }

  const EXTERNAL_URL_REGEX = new RegExp('^(https?|ftp|www)://')
  const externalURL = URL && EXTERNAL_URL_REGEX.test(URL)

  return (
    <>
      {externalURL ? (
        <Anchor href={URL} rel="noopener" target="_blank">
          {title}
        </Anchor>
      ) : (
        <Container activeClassName="active" to={URL}>
          {title}
        </Container>
      )}
    </>
  )
})

const Style = css`
  color: ${theme.colors.variants.neutralDark2};
  font-weight: 700;
  margin-right: 2.0625rem;
  transition: 0.15s ease-in-out;
  &:hover {
    color: ${theme.colors.variants.primaryDark1};
  }

  @media (max-width: 767px) {
    display: block;
    margin: 0 1.25rem;
  }
`

const Anchor = styled.a`
  ${Style}
`

const Container = styled(Link)`
  ${Style}
`
