import styled from '@emotion/styled'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

export interface Props {
  bookingEmail?: string
  infoEmail?: string
  languageCode: string
  managementEmail?: string
  phone?: string
}

export const Contacts = memo(function Contacts({
  bookingEmail,
  infoEmail,
  languageCode,
  managementEmail,
  phone,
}: Props) {
  return (
    <>
      {phone ? (
        <Anchor
          href={`tel:${phone}`}
          onClick={() => {
            if (isMobile) {
              typeof window.gtag !== 'undefined' &&
                window.gtag('event', 'Click', {
                  event_category: 'Website',
                  event_label: 'Phone Number',
                })
            }
          }}
        >
          {`${useVocabularyData('ph', languageCode)}: ${phone}`}
        </Anchor>
      ) : null}
      {infoEmail ? (
        <>
          <Divider />
          <Anchor
            href={`mailto:${infoEmail}`}
            onClick={() => {
              typeof window.gtag !== 'undefined' &&
                window.gtag('event', 'Click', {
                  event_category: 'Website',
                  event_label: 'Email Address',
                })
            }}
          >
            {`${useVocabularyData('informations', languageCode)}: ${infoEmail}`}
          </Anchor>
        </>
      ) : null}
      {managementEmail ? (
        <>
          <Divider />
          <Anchor
            href={`mailto:${managementEmail}`}
            onClick={() => {
              typeof window.gtag !== 'undefined' &&
                window.gtag('event', 'Click', {
                  event_category: 'Website',
                  event_label: 'Email Address',
                })
            }}
          >
            {`${useVocabularyData(
              'management',
              languageCode,
            )}: ${managementEmail}`}
          </Anchor>
        </>
      ) : null}
      {bookingEmail ? (
        <>
          <Divider />
          <Anchor
            href={`mailto:${bookingEmail}`}
            onClick={() => {
              typeof window.gtag !== 'undefined' &&
                window.gtag('event', 'Click', {
                  event_category: 'Website',
                  event_label: 'Email Address',
                })
            }}
          >
            {`${useVocabularyData(
              'reservations',
              languageCode,
            )}: ${bookingEmail}`}
          </Anchor>
        </>
      ) : null}
    </>
  )
})

const Anchor = styled.a`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
  &:hover {
    text-decoration: underline;
  }
`

const Divider = styled.div``
