import styled from '@emotion/styled'
import { Brand } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { motion } from 'framer-motion'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

import { MenuItem, Props as MenuItemProps } from './MenuItem'

export interface Props {
  beLanguage?: string
  email?: string
  IBEURL?: string
  languageCode: string
  menuItems: MenuItemProps[]
  modalStatus?: boolean
  phone?: string
}

export const Navigation = memo(function Navigation({
  beLanguage,
  email,
  IBEURL,
  languageCode,
  menuItems,
  modalStatus,
  phone,
}: Props) {
  const variants = {
    visible: (i: any) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.1,
        stiffness: 1000,
        velocity: -100,
      },
    }),
    hidden: { opacity: 0, x: -60 },
  }

  return (
    <Container onClick={(e) => e.stopPropagation()}>
      <Brand />

      {menuItems ? (
        <Nav>
          <NavList>
            {menuItems.slice(0, menuItems.length / 2 + 1).map((item, index) => (
              <motion.li
                key={index}
                custom={index}
                animate={modalStatus ? 'visible' : 'hidden'}
                variants={variants}
              >
                <MenuItem modalStatus={modalStatus} {...item} />
              </motion.li>
            ))}
          </NavList>
          <NavList>
            {menuItems
              .slice(menuItems.length / 2 + 1, menuItems.length)
              .map((item, index) => (
                <motion.li
                  key={index}
                  custom={index}
                  animate={modalStatus ? 'visible' : 'hidden'}
                  variants={variants}
                >
                  <MenuItem modalStatus={modalStatus} {...item} />
                </motion.li>
              ))}
            {IBEURL ? (
              <motion.li
                custom={menuItems.length + 1}
                animate={modalStatus ? 'visible' : 'hidden'}
                variants={variants}
              >
                <IBEButton
                  href={`${IBEURL}&lingua_int=${beLanguage}`}
                  rel="noopener"
                  target="_blank"
                >
                  {useVocabularyData('book', languageCode)}
                </IBEButton>
              </motion.li>
            ) : null}
          </NavList>
        </Nav>
      ) : null}

      {email || phone ? (
        <Bottom dial={5} row wrap>
          {phone ? (
            <Anchor
              href={`tel:${phone}`}
              onClick={() => {
                if (isMobile) {
                  typeof window.gtag !== 'undefined' &&
                    window.gtag('event', 'Click', {
                      event_category: 'Website',
                      event_label: 'Phone Number',
                    })
                }
              }}
            >
              {`${useVocabularyData('ph', languageCode)}: ${phone}`}
            </Anchor>
          ) : null}
          {email ? (
            <Anchor
              href={`mailto:${email}`}
              onClick={() => {
                typeof window.gtag !== 'undefined' &&
                  window.gtag('event', 'Click', {
                    event_category: 'Website',
                    event_label: 'Email Address',
                  })
              }}
            >
              {`${useVocabularyData('email', languageCode)}: ${email}`}
            </Anchor>
          ) : null}
        </Bottom>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.primaryDark2};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;

  > svg {
    width: auto;
    height: 3.75rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
    position: absolute;
    top: 3.625rem;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: 1199px) {
    > svg {
      right: 3rem;
      left: auto;
      transform: none;
    }
  }

  @media (max-width: 1023px) {
    overflow-y: auto;
    -webkit-overflow-scrolling: auto;

    > svg {
      display: none;
    }
  }
`

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 50%;
  position: absolute;
  top: 24vh;
  bottom: 19.3vh;
  left: 8.333vw;

  @media (max-width: 1199px) {
    width: 100%;
  }

  @media (max-width: 1023px) {
    display: block;
    position: relative;
    top: auto;
    left: auto;
    bottom: auto;
    padding: 6.25rem 0 6.25rem 120px;
  }

  @media (max-width: 767px) {
    padding: 6.25rem 0 6.25rem 3.75rem;
  }
`

const NavList = styled.ul`
  width: 50%;
  li {
    margin-top: 3vh;
  }

  @media (max-width: 1023px) {
    width: 100%;
    li {
      margin-top: 2rem;
    }
  }

  @media (max-width: 767px) {
    li {
      opacity: 1 !important;
      transform: none !important;
    }
  }
`

const IBEButton = styled.a`
  display: none;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  letter-spacing: 0.0938rem;
  line-height: 1.6875rem;
  text-transform: uppercase;

  @media (max-width: 767px) {
    display: inline-block;
  }
`

const Bottom = styled(FlexBox)`
  position: absolute;
  bottom: 1.875rem;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 1023px) {
    display: none;
  }
`

const Anchor = styled.a`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: -0.0313rem;
  line-height: 1.5rem;
  &:hover {
    text-decoration: underline;
  }
  &:nth-of-type(2) {
    display: flex;
    align-items: center;
    &:before {
      content: '';
      width: 0.125rem;
      height: 0.875rem;
      background: ${({ theme }) => theme.colors.variants.neutralLight4};
      margin: 0 0.75rem;
    }
  }
`
