import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { HeaderContext } from 'app/containers/Header'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import { Link } from 'gatsby'
import React, { memo, useContext, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'

interface Props {
  contactsURL?: string
}

export const Toolbars = memo(function Toolbars({ contactsURL }: Props) {
  const { email, IBEURL, languageCode, phone } = useContext(
    HeaderContext,
  ) as any

  let beLanguage = ''
  switch (languageCode) {
    case 'de-DE':
      beLanguage = 'deu'
      break
    case 'en-US':
      beLanguage = 'eng'
      break
    case 'es-ES':
      beLanguage = 'esp'
      break
    case 'fr-FR':
      beLanguage = 'fra'
      break

    default:
      beLanguage = 'ita'
      break
  }

  const [scrollDir, setScrollDir] = useState('up')

  useEffect(() => {
    const threshold = 0
    let lastScrollY = window.pageYOffset
    let ticking = false

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false
        return
      }
      setScrollDir(scrollY > lastScrollY ? 'down' : 'up')
      lastScrollY = scrollY > 0 ? scrollY : 0
      ticking = false
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir)
        ticking = true
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollDir])

  return (
    <Container
      className={scrollDir == 'down' ? 'hidden' : ''}
      dial={5}
      row
      stretch
    >
      {phone ? (
        <Item
          href={`tel:${phone}`}
          onClick={() => {
            if (isMobile) {
              typeof window.gtag !== 'undefined' &&
                window.gtag('event', 'Click', {
                  event_category: 'Website',
                  event_label: 'Phone Number',
                })
            }
          }}
        >
          {useVocabularyData('telephone', languageCode)}
        </Item>
      ) : null}
      {contactsURL ? (
        <Link to={contactsURL}>
          {useVocabularyData('ask-for', languageCode)}
        </Link>
      ) : null}
      {IBEURL ? (
        <Item
          className="toolbar-book"
          href={`${IBEURL}&lingua_int=${beLanguage}`}
          rel="noopener"
          target="_blank"
        >
          {useVocabularyData('book', languageCode)}
        </Item>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  box-shadow: ${rgba(theme.colors.variants.neutralDark1, 0.12)} 0 2px 8px;
  border-radius: 3.75rem;
  overflow: hidden;
  position: fixed;
  bottom: 1.25rem;
  left: 50%;
  z-index: 50;
  transition: 0.3s ease-out;
  transform: translateX(-50%);
  &.hidden {
    transform: translate(-50%, 6rem);
  }

  a {
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    color: ${({ theme }) => theme.colors.variants.primaryDark1};
    font-family: ${({ theme }) => theme.fontFamily.paragraph};
    font-size: 0.625rem;
    font-weight: 600;
    letter-spacing: 0.0875rem;
    line-height: 1.25rem;
    padding: 0.75rem 1.125rem;
    text-align: center;
    text-transform: uppercase;
    &:last-of-type {
      background: ${({ theme }) => theme.colors.variants.primaryDark1};
      color: ${({ theme }) => theme.colors.variants.neutralLight4};
    }
  }

  @media (min-width: 768px) {
    a.toolbar-book {
      display: none;
    }
  }
`

const Item = styled.a``
